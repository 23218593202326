// Utilities
import ready from './utils/ready';
import {
    validateEmail,
    validatePassword,
    validateRecaptcha,
} from './utils/validate';

// Constants
const formId = 'registration-form';
const toggleRegisterId = 'toggleRegister';
const toggleSignInId = 'toggleSignIn';
const signinId = 'signin';
const registerId = 'register';

/**
 * @function handleFormSubmit
 * Handle recaptcha on form submit
 *
 * @param {HTMLElement} form - The submitted form element
 * @return {void}
 */
function handleFormSubmit(form) {
    const recaptchaToken = form.dataset.sitekey;
    const submit = form.querySelector('[type="submit"]');

    submit.disabled = true;

    if (form.hasAttribute('data-recaptcha-done')) {
        form.submit();
        return;
    }

    if (handleRecaptchaValidation(submit) !== false) {
        grecaptcha
            .execute(recaptchaToken, { action: 'submit' })
            .then((token) => {
                const input = document.createElement('input');

                input.type = 'hidden';
                input.name = 'g-recaptcha-response';
                input.value = token;
                form.appendChild(input);
                form.setAttribute('data-recaptcha-done', 'true');
                form.submit();
            });
    }
}

/**
 * @function handleRecaptchaValidation
 * Handles form recaptcha validation
 *
 * @return {boolean}
 */
function handleRecaptchaValidation(submit) {
    const [valid, errorText] = validateRecaptcha();

    if (!valid && errorText !== '') {
        const alertDiv = document.createElement('div');
        alertDiv.classList.add('alert', 'alert-warning', 'mb-4', 'd-block');
        alertDiv.setAttribute('role', 'alert');
        alertDiv.textContent = errorText;
        submit.parentNode.insertBefore(alertDiv, submit);
    }

    return valid;
}

/**
 * @function handleFieldValidation
 * Handles form validation on various fields
 *
 * @param {HTMLElement} field - The field to validate
 * @param {string} type - The type of validation to perform
 * @return {boolean}
 */
function handleFieldValidation(field, type) {
    const errorsList = document.getElementById(`${field.id}-errors`);
    const [valid, errorText] =
        type == 'email'
            ? validateEmail(field.value)
            : validatePassword(field.value);

    if (!valid && errorText !== '') {
        errorsList.classList.replace('d-none', 'd-block');
        errorsList.innerHTML = errorText;
    } else {
        errorsList.classList.replace('d-block', 'd-none');
        errorsList.innerHTML = '';
    }

    return valid;
}

/**
 * @function handleFormToggle
 * Toggle visible form
 *
 * @param {HTMLElement} show - The form to show
 * @param {HTMLElement} hide - The form to hide
 * @return {void}
 */
function handleFormToggle(show, hide) {
    if (show) {
        show.classList.replace('d-none', 'd-block');
    }

    if (hide) {
        hide.classList.replace('d-block', 'd-none');
    }
}

/**
 * @function handlePasswordToggle
 * Toggle visible password indicator
 *
 * @param {HTMLElement} trigger - The element clicked to toggle the password
 * @return {void}
 */
function handlePasswordToggle(trigger) {
    const field = document.getElementById(trigger.dataset.target);
    e.preventDefault();

    if (field.type === 'text') {
        field.type = 'password';
        trigger
            .querySelector('.fas')
            .classList.replace('fa-eye-slash', 'fa-eye');
    } else {
        field.type = 'text';
        trigger
            .querySelector('.fas')
            .classList.replace('fa-eye', 'fa-eye-slash');
    }
}

/**
 * Set up default effects and attach event listeners
 */
ready(() => {
    const hash = window.location.hash;
    const form = document.getElementById(formId);
    const signin = document.getElementById(signinId);
    const register = document.getElementById(registerId);
    const toggleRegister = document.getElementById(toggleRegisterId);
    const toggleSignIn = document.getElementById(toggleSignInId);
    const togglePassword = document.querySelectorAll('.form-control-icon');
    const emailField = document.getElementById('registration_form_email');
    const passwordField = document.getElementById('registration_form_password');
    const passwordFields = document.querySelectorAll(
        '#password_sign, #registration_form_password'
    );

    if (typeof hash !== 'undefined' && hash === '#create') {
        handleFormToggle(register, signin);
    }

    if (form) {
        form.addEventListener('submit', (event) => {
            const emailValid = handleFieldValidation(emailField, 'email');
            const passwordValid = handleFieldValidation(
                passwordField,
                'password'
            );
            event.preventDefault();

            if (emailValid && passwordValid) {
                handleFormSubmit(form);
            }
        });
    }

    if (toggleRegister) {
        toggleRegister.addEventListener('click', (event) => {
            event.preventDefault();
            const submit = form.querySelector('[type="submit"]');

            submit.disabled;

            handleFormToggle(register, signin);
        });
    }

    if (toggleSignIn) {
        toggleSignIn.addEventListener('click', (event) => {
            event.preventDefault();
            handleFormToggle(signin, register);
        });
    }

    if (emailField) {
        emailField.removeAttribute('readonly');
        emailField.addEventListener('blur', () => {
            handleFieldValidation(emailField, 'email');
        });
    }

    if (passwordField) {
        passwordField.removeAttribute('readonly');
        passwordField.addEventListener('blur', () => {
            handleFieldValidation(passwordField, 'password');
        });
    }

    // if (passwordFields) {
    //     passwordFields.forEach((field) => {
    //         field.addEventListener('focus', () => {
    //             field.setAttribute('type', 'text');
    //         });

    //         field.addEventListener('blur', () => {
    //             field.setAttribute('type', 'password');
    //         });
    //     });
    // }

    if (togglePassword) {
        togglePassword.forEach((trigger) => {
            trigger.addEventListener('click', function (e) {
                handlePasswordToggle(trigger);
            });
        });
    }
});
