/**
 * @function validateEmail
 * Validate an email address string
 *
 * @param {string} email - The email address string to validate
 * @returns {boolean, string}
 */
export function validateEmail(email) {
    if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
        return [false, 'You must provide a valid email address.'];
    }

    return [true, ''];
}

/**
 * @function validatePassword
 * Validate a password string
 *
 * @param {string} password - The password string to validate
 * @returns {boolean, string}
 */
export function validatePassword(password) {
    if (password.length < 8 || password.length > 20) {
        return [false, 'Your password must be between 8 and 20 characters.'];
    }

    if (/\s/.test(password)) {
        return [false, 'Your password cannot contain spaces.'];
    }

    if (!/\d/.test(password)) {
        return [false, 'Your password must contain at least one number.'];
    }

    if (!/[a-zA-Z]/.test(password)) {
        return [false, 'Your password should contain at least one letter.'];
    }

    return [true, ''];
}

/**
 * @function validateRecaptcha
 * Checks that the recaptcha execute function exists
 *
 * @returns {boolean, string}
 */
export function validateRecaptcha() {
    const recaptchaFeedback =
        'There was an issue loading recaptcha, please reload the page and try again.';

    if (typeof grecaptcha === 'undefined') {
        return [false, recaptchaFeedback];
    }

    if (typeof grecaptcha.execute !== 'function') {
        return [false, recaptchaFeedback];
    }

    return [true, ''];
}
