/**
 * @function ready
 * Perform a function if the document is in a ready state, or attach the
 * function to an event handler if not
 *
 * @return {void}
 */
export default function ready(fn) {
    if (document.readyState !== 'loading') {
        fn();
    } else {
        document.addEventListener('DOMContentLoaded', fn);
    }
}
